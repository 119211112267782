/* eslint-disable no-console */
<template>
  <div>
    <v-toolbar
      flat
    >
      <v-btn
        class="mx-4"
        small
        text
        @click="navigateToList"
      >
        <v-icon class="mx-2">mdi-view-headline</v-icon>
        {{ crudLabels.list }}
      </v-btn>

      <v-btn
        class="mx-4"
        color="primary"
        small
        text
        @click="navigateToFormInsert"
      >
        <v-icon  class="mx-2">mdi-playlist-plus</v-icon>
        {{ crudLabels.create }}
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <!-- Editable form: begin -->
          <v-form ref="form" v-model="isFormContentValid" dense>
            <!-- Main Section Fields -->

            <v-row>
              <v-col cols="12" sm="4" md="4" dense >
                <v-select
                  v-model="selectedLocationType"
                  @change="loadSelectedLocationList"
                  :label="$tc('stationlocation.fields.locationType', 0)"
                  :items="locationTypeItems"
                  item-text="label"
                  item-value="value"
                  clearable
                  clear-icon="mdi-eraser"
                  dense
                  :type="templateFieldType(locationType)"
                ></v-select>
              </v-col>
            <!-- </v-row>

            <v-row> -->
              <v-col cols="12" sm="8" md="8" dense >
                <v-select v-if="isSelectedLocationTypeDoor"
                  v-model="selectedLocationId"
                  :items="refLocationDoorList"
                  :label="$tc('stationlocation.fields.name', 2)"
                  :disabled="!selectedLocationType"
                  item-text="name"
                  item-value="id"
                  dense
                  clearable
                  clear-icon="mdi-eraser"
                ></v-select>
                <v-select v-if="isSelectedLocationTypeZone"
                  v-model="selectedLocationId"
                  :items="refLocationZoneListInSelect"
                  :label="$tc('stationlocation.fields.name', 2)"
                  :disabled="!selectedLocationType"
                  item-text="text"
                  item-value="id"
                  dense
                  clearable
                  clear-icon="mdi-eraser"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" dense >
                <v-select
                  v-model="selectedStationId"
                  :items="refStationListInSelect"
                  item-text="text"
                  item-value="value"
                  :label="$tc('station.label', 1)"
                  dense
                  clearable
                  clear-icon="mdi-eraser"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
          <!-- Editable form: end -->
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!isNewItem"
          color="orange darken-1"
          text
          @click="displayDialogDelete && crudAccess.delete"
        >
          {{ crudLabels.delete }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          {{ $t("appButton.cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
          :disabled="!isFormContentValid"
        >
          {{ $t("appButton.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="isDisplayedDialogDelete" max-width="800px">
      <v-card>
        <v-card-title class="stong">{{ crudLabels.confirmDelete }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogDelete"
            >{{ $t("appButton.cancel") }}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDeleteItem"
            >{{ $t("appButton.delete") }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {
  stationLocation,
  // stationLocationFieldId,
  // stationLocationFieldStationId,
  // stationLocationFieldStationName,
  // stationLocationFieldStationParams,
  // stationLocationFieldIsActive,
  // stationLocationFieldLocationId,
  stationLocationFielLocationType,
  // stationLocationFieldFloorNumber,
  // stationLocationFieldReference,
  // stationLocationFieldName,
  // stationLocationFieldCapacity,
} from '@/model/stationlocation';
import {
  // datatypes,
  // baseDatatypes,
  // reservedDatatypes,
  // getBooleanLabel,
  getTemplateFieldType,
} from '@/model/datatypes';
import {
  locationTypes,
  // DOOR,
  // ZONE,
  getLocationTypeReferences,
  // getLocationTypeLabel,
} from '@/model/locationType';
import { mapGetters } from 'vuex';
import { urlFormatter } from '@/router/index';
import dbAPI from '../api/stationslocations.api';

import doorsAPI from '../api/doors.api';
import stationsAPI from '../api/stations.api';
import zonesAPI from '../api/zones.api';
import i18n from '../i18n';
import { defaultCrudAccess, getAllowedCrudForUser } from '../model/accessRolesModel';

export const DEFAULT_VALUES = {
  editedId: '',
  newItem: {
    stationId: '',
    locationId: '',
  },
};

export default {
  name: 'StationLocationForm',
  components: {
  },
  data: () => ({
    tablename: 'stationlocation',
    crudAccess: defaultCrudAccess,
    crudLabels: {},
    editedId: DEFAULT_VALUES.editedId,
    editedItem: {},
    isDisplayedDialogDelete: false,
    isFormContentValid: false,
    loading: true,
    model: [],
    record: {},
    records: [],
    refLocationDoorList: [],
    refLocationZoneList: [],
    refStationList: [],
    selectedLocationId: [],
    selectedLocationType: null,
    selectedStationId: [],
    runningStatus: '',
    tableHeaders: [],
  }),

  created() {
    this.crudAccess = getAllowedCrudForUser(stationLocation.crudAllowedRoles, this.userRoles);
    if (!this.crudAccess.read && !this.crudAccess.create && !this.crudAccess.update) {
      const msg = this.$t('errMsg.forbiddenAccess_fmt',
        { operation: this.$t('crudLabels.read_write').toLowerCase() });
      this.$store.dispatch('displayAlert', msg);
      this.$router.go(-1);
    }
    this.refreshData();
  },

  watch: {
    $route() {
      this.refreshData();
    },
  },

  computed: {
    ...mapGetters([
      'userRoles',
    ]),
    formTitle() {
      return this.isNewItem ? this.crudLabels.create : this.crudLabels.update;
    },

    isNewItem() {
      return this.editedId === DEFAULT_VALUES.editedId;
    },

    locationType() {
      return stationLocationFielLocationType;
    },

    locationTypeItems() {
      return getLocationTypeReferences(1);
    },

    isSelectedLocationTypeDoor() {
      return this.selectedLocationType === locationTypes.door;
    },

    isSelectedLocationTypeZone() {
      return this.selectedLocationType === locationTypes.zone;
    },

    refLocationZoneListInSelect() {
      return this.refLocationZoneList.map((rec) => ({
        id: rec.id,
        text: `R${rec.floorNumber}  - ${rec.name} (ref: ${rec.reference})`,
      })).sort((a, b) => (b.text.toLowerCase() < a.text.toLowerCase()));
    },

    refStationListInSelect() {
      return this.refStationList.map((rec) => ({
        value: rec.id,
        text: `${rec.stationName} (actif: ${rec.stationIsActive})`,
      })).sort((a, b) => (b.text.toLowerCase() < a.text.toLowerCase()));
    },

    selectedLocation() {
      if (!this.selectedLocationType) return null;
      if (!this.selectedLocationId) return null;
      if (this.selectedLocationType === locationTypes.door) {
        return this.refLocationDoorList.find((loc) => loc.id === this.selectedLocationId);
      }
      if (this.selectedLocationType === locationTypes.zone) {
        return this.refLocationZoneList.find((loc) => loc.id === this.selectedLocationId);
      }
      throw Error('Unexpected behavior: selectedLocation should be DOOR or ZONE.');
    },

    selectedStation() {
      if (!this.selectedStationId) return null;
      return this.refStationList.find((sta) => sta.id === this.selectedStationId);
    },

  },

  methods: {
    confirmDeleteItem() {
      this.$store.dispatch('displayAlert', 'Not implemented : stationsLocation.confirmDeleteItem().');
      this.closeDialogDelete();
    },

    close() {
      this.$router.push(urlFormatter.stationLocationList);
    },

    closeDialogDelete() {
      this.isDisplayedDialogDelete = false;
    },

    createRecordThenClose() {
      let record = {};
      let created = false;
      const complete = this.validateRecordBeforeWriteAPICall(record);
      /* eslint-disable no-param-reassign */
      if (complete) record = complete;
      else return;
      /* eslint-enable no-param-reassign */
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.creating') }));
      this.$nextTick(() => {
        dbAPI.create(record)
          .then((data) => {
            created = true;
            if (data.status === 201) {
              if (data.data.stationIsActive || this.isListGetAll) {
                this.records.push(data.data);
              }
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.created') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.creation').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
            if (created) this.close();
          });
      });
    },

    displayDialogDelete() {
      this.isDisplayedDialogDelete = true;
    },

    getRefStationList() {
      stationsAPI.getList().then((response) => {
        this.refStationList = response.data.data;
        // this.setModelReferenceList();
      }).catch((error) => {
        const msg = this.$tc(`${'station'}.abortedMsg_Fmt`, 2,
          { operation: this.$t('crudLabels.read').toLowerCase() });
        const apiErrorMsg = error?.response?.data?.error_msg || '';
        console.error(`Error : ${msg}`, error, apiErrorMsg);
        this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
      });
    },

    getRefLocationDoorList() {
      this.loading = true;
      this.$nextTick(() => {
        doorsAPI.getList().then((response) => {
          this.refLocationDoorList = response.data.data;
        }).catch((error) => {
          const msg = this.$tc(`${'door'}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    getRefLocationZoneList() {
      this.loading = true;
      this.$nextTick(() => {
        zonesAPI.getList().then((response) => {
          this.refLocationZoneList = response.data.data;
        }).catch((error) => {
          const msg = this.$tc(`${'zone'}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    getList() {
      this.loading = true;
      this.$nextTick(() => {
        this.getRefStationList();
        dbAPI.getList().then((response) => {
          this.records = response.data.data;
          /* eslint-disable no-param-reassign */
          this.records.map((sta) => {
            if (sta.stationParams && typeof sta.stationParams === 'object') {
              sta.stationParams = `${JSON.stringify(sta.stationParams)}`;
            }
            return sta;
          });
          /* eslint-disable no-param-reassign */
        }).catch((error) => {
          const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    loadSelectedLocationList() {
      // console.log(`in loadSelectedLocationList, ${this.selectedLocationType}, length(
      //   door ${this.refLocationDoorList.length}
      //   zone ${this.refLocationZoneList.length})`);
      if (this.selectedLocationType === locationTypes.door
      && this.refLocationDoorList.length === 0) {
        this.getRefLocationDoorList();
      } else if (this.selectedLocationType === locationTypes.zone
      && this.refLocationZoneList.length === 0) {
        this.getRefLocationZoneList();
      }
    },

    navigateToFormInsert() {
      if (this.$refs?.form) this.$refs.form.resetValidation();
      this.$router.push(urlFormatter.stationLocationFormNew);
    },

    navigateToList() {
      if (this.$refs?.form) this.$refs.form.resetValidation();
      this.$router.push(urlFormatter.stationLocationList);
    },

    refreshData() {
      try {
        this.crudAccess = getAllowedCrudForUser(stationLocation.crudAllowedRoles, this.userRoles);
        this.editedId = this.$route.params.id ?? DEFAULT_VALUES.editedId;
        this.setCrudLabels();
        // Default Empty Item
        this.editedItem = { ...DEFAULT_VALUES.newItem };
        // this.editedItem = this.localizeDataValues(this.editedItem);
        this.getList();
      } catch (error) {
        this.$store.dispatch('displayAlert', error.message);
        throw new Error(this.$t('errMsg.validationError'), error);
      } finally {
        this.loading = false;
      }
    },

    save() {
      if (this.isNewItem) {
        this.createRecordThenClose();
      } else {
        this.updateRecordThenClose();
      }
    },

    setCrudLabels() {
      this.crudLabels = {
        create: i18n.t(`${this.tablename}.c`),
        retrieve: i18n.tc(`${this.tablename}.r`, 1),
        update: i18n.tc(`${this.tablename}.u`, 1),
        delete: i18n.tc(`${this.tablename}.d`, 1),
        list: i18n.tc(`${this.tablename}.list`, 1),
        confirmDelete: i18n.tc(`${this.tablename}.confirmD`, 1),
      };
    },

    templateFieldType(field) {
      return getTemplateFieldType(field.type);
    },

    updateRecordThenClose() {
      throw Error('updateRecordThenClose : method copied but not tested ! Do not use like this !');
      // let editedRecord = {};
      // const complete = this.validateRecordBeforeWriteAPICall(editedRecord);
      // /* eslint-disable no-param-reassign */
      // if (complete) editedRecord = complete;
      // else return;
      // /* eslint-enable no-param-reassign */
      // this.loading = true;
      // this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
      //   { operation: this.$t('crudLabels.updating') }));
      // this.$nextTick(() => {
      //   dbAPI.update(editedRecord.id, editedRecord)
      //     .then((data) => {
      //       if (data.status === 201) {
      //         const localIndex = this.records.findIndex((item) => (item.id === editedRecord.id));
      //         this.records.splice(localIndex, 1, data.data);
      //         this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
      //           { operation: this.$t('crudLabels.updated') }));
      //       }
      //     })
      //     .catch((error) => {
      //       const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
      //         { operation: this.$t('crudLabels.update').toLowerCase() });
      //       const apiErrorMsg = error?.response?.data?.error_msg || '';
      //       console.error(`Error : ${msg}`, error, apiErrorMsg);
      //       this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
      //     })
      //     .then(
      //       this.loading = false,
      //       this.close(),
      //     );
      // });
    },

    validateRecordBeforeWriteAPICall(record) {
      let result = null;
      try {
        const sta = this.selectedStation;
        // console.log(sta);
        if (!sta) throw ReferenceError('Unable to retrieve selected Station !');
        const loc = this.selectedLocation;
        if (!loc) throw ReferenceError('Unable to retrieve selected Location !');
        // console.log(loc);
        /* eslint-disable no-param-reassign */
        // record.stationId = sta.id;
        record.pK = sta.id;
        record.stationName = sta.stationName;
        record.stationParams = sta.stationParams;
        record.stationIsActive = sta.stationIsActive;
        // record.locationId = loc.id;
        record.sK = loc.id;
        record.locationType = loc.locationType;
        record.floorNumber = loc.floorNumber;
        record.reference = loc.reference;
        record.name = loc.name;
        record.capacity = loc.capacity;
        /* eslint-enable no-param-reassign */
        // console.log(record);
        result = record;
      } catch (error) {
        const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
          { operation: this.$t('crudLabels.creation').toLowerCase() });
        this.$store.dispatch('displayAlert', `${msg}: ${error}.`);
      }
      return result;
    },

  },
};
</script>
